import './Header.scss';
import InfoBox from './InfoBox';
import { useState } from 'react';
const Header = (props) => {
  const [openMenu, setOpenMenu] = useState(false);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  }

  const openMaterialModal = () => {
    document.getElementById('openMaterialModalBtn').click();
    toggleMenu();
  }

  const openSizeChartModal = () => {
    document.getElementById('openSizeChartModalBtn').click();
    toggleMenu();
  }

  const openSim = () => {
    document.getElementById('openSimBtn').click();
    toggleMenu();
  }

  return (
    <>
      <header className="header">
        <div className={`menu ${openMenu ? 'menu-open' : ''}`} onClick={() => toggleMenu()}></div>
        <div className="header-content">
          <div className="left-box">
            <a className="logo" href="/">
              <img
                src={`${process.env.PUBLIC_URL}/img/logo.png`}
                className="header-logo"
                alt="logo"
              />
            </a>
            <h1>Quick order System</h1>
            <a href="/QO_HOWtoOrder.pdf" target="_blank" rel="noreferrer" className="header-sim-link">
              &gt;&gt; シミュレーターの使い方はこちら
            </a>
          </div>
          <InfoBox/>
        </div>
      </header>
      {openMenu && (
        <div className="menu-content">
          <ul className="menu-links">
            <li className="menu-link fs14"><a href="/QO_HOWtoOrder.pdf" target="_blank">シミュレーターの使い方を見る</a></li>
            <li className="menu-link fs14" onClick={() => openMaterialModal()}>素材表を見る</li>
            <li className="menu-link fs14" onClick={() => openSizeChartModal()}>サイズ表を見る</li>
          </ul>
          <div className="simulator-open-area">
            <p className="yellow-color fs10 text-align-c">アイテムやマーク加工を選択してオリジナルデザインを作成しよう！</p>
            <button
              className="simulator-open-btn yellow-color mt10 fs14"
              onClick={() => openSim()}
            >
              オリジナルデザインを作成する
            </button>
          </div>

          <InfoBox />
        </div>
      )}
    </>
  );
};

export default Header
