import styled from 'styled-components';
import { COLOR_CODE_BY_ID } from '../../constants';

const MultiColorButton = styled.button`
  .front-color {
    background-color: ${({ $background_color_front }) => `#${COLOR_CODE_BY_ID[$background_color_front]}`}
  }
  .back-color {
    background-color: ${({ $background_color_back }) => `#${COLOR_CODE_BY_ID[$background_color_back]}`}
  }
`
export default MultiColorButton
