const T_Json = await import(`./json/${process.env.REACT_APP_MODE}/t.json`);
const TL_Json = await import(`./json/${process.env.REACT_APP_MODE}/tl.json`);
const P_Json = await import(`./json/${process.env.REACT_APP_MODE}/p.json`);
const PS_Json = await import(`./json/${process.env.REACT_APP_MODE}/ps.json`);
const PSL_Json = await import(`./json/${process.env.REACT_APP_MODE}/psl.json`);
const B_Json = await import(`./json/${process.env.REACT_APP_MODE}/b.json`);
const BB_Json = await import(`./json/${process.env.REACT_APP_MODE}/bb.json`);
const SO_Json = await import(`./json/${process.env.REACT_APP_MODE}/so.json`);
const T_COLORS_Json = await import(`./json/${process.env.REACT_APP_MODE}/t_colors.json`);
const TL_COLORS_Json = await import(`./json/${process.env.REACT_APP_MODE}/tl_colors.json`);
const P_COLORS_Json = await import(`./json/${process.env.REACT_APP_MODE}/p_colors.json`);
const PS_COLORS_Json = await import(`./json/${process.env.REACT_APP_MODE}/ps_colors.json`);
const PSL_COLORS_Json = await import(`./json/${process.env.REACT_APP_MODE}/psl_colors.json`);
const B_COLORS_Json = await import(`./json/${process.env.REACT_APP_MODE}/b_colors.json`);
const BB_COLORS_Json = await import(`./json/${process.env.REACT_APP_MODE}/bb_colors.json`);
const SO_COLORS_Json = await import(`./json/${process.env.REACT_APP_MODE}/so_colors.json`);

export const LOCAL_STORAGE_ITEM_KEY = 'athleta_simulation';
export const LOCAL_STORAGE_ORDER_FORM_KEY = 'athleta_order_form';
export const LOCAL_STORAGE_ENCODED_SAVED_ITEM_KEY = 'athleta_simulation_encoded';
export const BITLY_API_ACCESS_TOKEN = 'cb9d387d8df9c927357a02ff0b14ccf6799e08a9'
export const BITLY_API_URL = 'https://api-ssl.bitly.com/v4/shorten';
export const ITEM_SETTINGS_BY_CATEGORY = {
  t: T_Json.product.handles,
  tl: TL_Json.product.handles,
  p: P_Json.product.handles,
  ps: PS_Json.product.handles,
  psl: PSL_Json.product.handles,
  b: B_Json.product.handles,
  bb: BB_Json.product.handles,
  so: SO_Json.product.handles,
};
export const ITEM_COLORS_SETTINGS_BY_CATEGORY = {
  t: T_COLORS_Json.product.colors,
  tl: TL_COLORS_Json.product.colors,
  p: P_COLORS_Json.product.colors,
  ps: PS_COLORS_Json.product.colors,
  psl: PSL_COLORS_Json.product.colors,
  b: B_COLORS_Json.product.colors,
  bb: BB_COLORS_Json.product.colors,
  so: SO_COLORS_Json.product.colors,
}
export const LOCALIZED_BY_NAME = {
  teamName: "チーム名",
  athletaLogo: "アスレタロゴ",
  emblem: "エムブレム",
  personalName: "個人名",
  centerChest: "全胸",
  isNumberCenterChest: "全胸",
  sideChest: "片胸",
  isNumberSideChest: "片胸",
  back: "背中",
  isNumberBack: "背中",
  rightThigh: "右モモ",
  isNumberRightThigh: "右モモ",
  frontLeftHem: "全面左裾",
  isNumberFrontLeftHem: "全面左裾",
  frontCenter: "全胸",
  frontSide: "片胸",
  backTop: "背中上",
  backBottom: "背中下",
};
export const MANNEQUIN_DEFAULT_SETTINGS = {
  "t": {
    itemContent: {
      id: "QOS-017",
      color: "10"
    }
  },
  "p": {
    itemContent: {
      id: "QOP-017",
      color: "10"
    }
  }
}
export const MANNEQUIN_UPPER_ITEM_BY_CATEGORY_TYPES = ["t","tl","ps","psl"];
export const MANNEQUIN_LOWER_ITEM_BY_CATEGORY_TYPES = ["p"];
export const CATEGORY_TYPE_BIBS = ["b","bb"];
export const MANNEQUIN_SETTINGS_BY_CATEGORY = {
  "t1": [
    {part: "arms", category: "arms", type: "mannequin"},
    {part: "t", category: "t1", type: "item"},
    {part: "t-number", category: "t1", type: "number"},
    {part: "t-name", category: "t1", type: "name"},
  ],
  "tl1": [
    {part: "tl", category: "tl1", type: "item"},
    {part: "arms-long", category: "arms-long", type: "mannequin"},
    {part: "tl-number", category: "tl1", type: "number"},
    {part: "tl-name", category: "tl1", type: "name"},
  ],
  "t2": [
    {part: "arms", category: "arms", type: "mannequin"},
    {part: "t", category: "t2", type: "item"},
    {part: "t-number", category: "t2", type: "number"},
    {part: "t-name", category: "t2", type: "name"},
  ],
  "tl2": [
    {part: "tl", category: "tl2", type: "item"},
    {part: "arms-long", category: "arms-long", type: "mannequin"},
    {part: "tl-number", category: "tl2", type: "number"},
    {part: "tl-name", category: "tl2", type: "name"},
  ],
  "p1": [
    {part: "p", category: "p1", type: "item"},
    {part: "p-number", category: "p1", type: "number"},
  ],
  "p2": [
    {part: "p", category: "p2", type: "item"},
    {part: "p-number", category: "p2", type: "number"},
  ],
  "ps": [
    {part: "arms", category: "arms", type: "mannequin"},
    {part: "ps", category: "ps", type: "item"},
    {part: "ps-number", category: "ps", type: "number"},
    {part: "ps-name", category: "ps", type: "name"},
  ],
  "psl": [
    {part: "psl", category: "psl", type: "item"},
    {part: "arms-long", category: "arms-long", type: "mannequin"},
    {part: "psl-number", category: "psl", type: "number"},
    {part: "psl-name", category: "psl", type: "name"},
  ],
  "b": [
    {part: "b", category: "b", type: "item"},
    {part: "b-number", category: "b", type: "number"},
    {part: "b-name", category: "b", type: "name"},
  ],
  "bb": [
    {part: "bb", category: "bb", type: "item"},
    {part: "bb-number", category: "bb", type: "number"},
    {part: "bb-name", category: "bb", type: "name"},
  ],
};
export const CATEGORY_BTNS = [
  {name: "トップス<br />半袖1", category: "t1", imgPath: "shirt.png"},
  {name: "トップス<br />長袖1", category: "tl1", imgPath: "l-shirt.png"},
  {name: "トップス<br />半袖2", category: "t2", imgPath: "shirt.png"},
  {name: "トップス<br />長袖2", category: "tl2", imgPath: "l-shirt.png"},
  {name: "パンツ1", category: "p1", imgPath: "pants.png"},
  {name: "パンツ2", category: "p2", imgPath: "pants.png"},
  {name: "プラクティスシャツ半袖", category: "ps", imgPath: "shirt.png"},
  {name: "プラクティスシャツ長袖", category: "psl", imgPath: "l-shirt.png"},
  {name: "片面ビブス", category: "b", imgPath: "bibs.png"},
  {name: "両面ビブス", category: "bb", imgPath: "bibs.png"},
  {name: "ソックス", category: "so", imgPath: "socks.png"},
];
export const COLOR_CODE_BY_ID = {
  10: "FFFFFF",
  20: "FFCE00",
  29: "E7FF00",
  30: "004538",
  33: "00833F",
  39: "94D600",
  40: "0047BB",
  44: "7BA5DE",
  49: "0075C9",
  50: "CF102D",
  51: "E94539",
  52: "F99BBB",
  55: "FF681D",
  56: "72253D",
  58: "B09D60",
  59: "E01783",
  60: "98999B",
  66: "54575A",
  70: "000000",
  90: "011E41",
  95: "773DBE",
};
export const COLOR_NAME_BY_ID = {
  10: "10WHT",
  20: "20YEL",
  29: "29FYE",
  30: "30DGR",
  33: "33KGR",
  39: "39FGR",
  40: "40BLU",
  44: "44SAX",
  49: "49FSA",
  50: "50RED",
  51: "51FRE",
  52: "52PNK",
  55: "55ORG",
  56: "56WIN",
  58: "58GLD",
  59: "59FPN",
  60: "60GRY",
  66: "66CHA",
  70: "70BLK",
  90: "90NVY",
  95: "95PPL",
};
export const COLOR_NAME_FOR_BB_BY_ID = {
  10: '10WHTx59FPN',
  20: '20YELx60GRY',
  39: '39FGRx55ORG',
  50: '50REDx58GLD',
  70: '70BLKx44SAX',
};
export const COLORS_FOR_BB_BY_ID = {
  10: ["10","59"],
  20: ["20","60"],
  39: ["39","55"],
  50: ["50","58"],
  70: ["70","44"],
};
export const FONT_FAMILY_BY_FONT_ID = {
  "AR-1": "AderaDisplaySSK",
  "AR-1S": "AderaDisplaySSK-italic",
  "AR-2": "CooperBlackStd",
  "AR-2S": "CooperBlackStd-italic",
  "AR-3": "Helvetica",
  "AR-3S": "Helvetica-BoldOblique",
  "AR-4": "ConexDisplaySSK",
  "AR-4S": "ConexDisplaySSK-italic",
  "AR-5": "StencilStd",
  "AR-6": "BrushScriptStd",
  "AR-7": "LetterSweaterDisplaySSiBlack",
  "AR-8": "TwinkieDisplaySSK",
  "AR-9": "JuliusBlack",
  "AR-10": "BORDO-B",
  "AR-11": "CaBLN",
  "AR-12": "DFktrxb"
};
export const CATEGORY_NAME_BY_CATEGORY = {
  t: "Tops",
  tl: "Tops",
  p: "Pants",
  ps: "Practice shirt",
  psl: "Practice shirt",
  b: "Bibs",
  bb: "Bibs",
  so: "Socks",
};
export const DEFAULT_SIZE_BY_CATEGORY = {
  t: "S",
  tl: "S",
  p: "S",
  ps: "S",
  psl: "S",
  b: "F",
  bb: "F",
  so: "2527",
};
export const JUNIOR_SIZE_BY_CATEGORY = {
  t: "110",
  tl: "110",
  p: "110",
  ps: "110",
  psl: "110",
  b: "JF",
  bb: "JF",
  so: "2224",
};
export const NAME_DEFAULT_SCALE_BY_POSITION = {
  "frontCenter": {
    "upperText": 0.9,
    "lowerText": 0.6
  },
  "frontSide": {
    "upperText": 0.6,
    "lowerText": 0.35
  },
  "backTop": {
    "upperText": 0.9,
    "lowerText": 0.6,
    "personalName":  0.9
  },
  "backBottom": {
    "upperText": 0.9,
    "lowerText": 0.6,
    "personalName":  0.9
  }
};
export const SIZE_TABLE_ROWS_BY_CATEGORY = {
  t: [
    ["サイズ","110","120","130","140","150","160","S","M","L","O","XO","2XO"],
    ["身丈(SNP〜)","48","51","54","57","60","63","65","68","71","74","77","80"],
    ["身幅","36","38","40","42","44","46","47","50","53","56","59","62"],
    ["肩幅","33.8","35.2","36.6","38","39.4","40.8","41.6","43.7","45.8","47.9","50","52.1"],
    ["袖幅","14","15","16","17","18","19","19.5","20.5","21.5","22.5","23.5","24.5"],
    ["袖丈 - 半袖","11.6","12.9","14.2","15.5","16.8","18.1","18.7","20.6","22.5","24.4","26.3","28.3"],
    ["袖口幅 - 半袖","10.5","11","11.5","12","12.5","13","13.5","14.5","15.5","16.5","17.5","18.5"],
    ["袖丈 - 長袖","36.1","39.9","45.2","48.5","51.8","55.1","58.7","60.6","62.5","64.4","66.3","68.2"],
    ["袖口幅 - 長袖","8.5","9","9","9.5","9.5","10","10","10.5","10.5","11","11.5","12"],
  ],
  p: [
    ["サイズ","110","120","130","140","150","160","S","M","L","O","XO","2XO"],
    ["前股上","21","22","23","24","25","26","27","28","29","30","31","32"],
    ["後股上","32","33","34","35","36","37","38","39","40","41","42","43"],
    ["股下","6.5","7.5","8.5","8.5","10.5","12.5","14.5","15.5","16.5","17.5","18.5","19.5"],
    ["ウエスト幅","44","46","48","50","52","56","60","63","66","69","72","75"],
    ["ベルト幅","3.5","3.5","3.5","3.5","3.5","3.5","3.5","3.5","3.5","3.5","3.5","3.5"],
    ["渡り幅","26.5","27.5","28.5","29.5","31.5","32.5","34","35","36","37","38","39"],
    ["裾幅","21.5","22.5","23.5","24.5","24.5","25.5","27","28","29","30","31","32"]
  ],
  b: [
    ["サイズ","J","F"],
    ["着丈","56","70"],
    ["身幅","53","60"],
    ["肩幅","35","44"],
    ["AH","22","27"],
    ["天幅","18","21"],
    ["裾幅","53","60"],
    ["前下（内）","11","13"],
    ["後下（内）","2","3"],
  ],
  ps: [
    ["サイズ","110","120","130","140","150","160","S","M","L","O","XO","2XO"],
    ["身丈(SNP〜)","48","51","54","57","60","63","65","68","71","74","77","80"],
    ["身幅","36","38","40","42","44","46","47","50","53","56","59","62"],
    ["袖幅","14","15","16","17","18","19","19.5","20.5","21.5","22.5","23.5","24.5"],
    ["桁丈 - 半袖","28","30","32","34","36","38","39","42","45","48","51","54"],
    ["袖口幅 - 半袖","10.5","11","11.5","12","12.5","13","13.5","14.5","15.5","16.5","17.5","18.5"],
    ["桁丈 - 長袖","52.5","57","63","67","71","75","79","82","85","88","91","94"],
    ["袖口幅 - 長袖","8.5","9","9","9.5","9.5","10","10","10.5","10.5","11","11.5","12"],
  ]
};
