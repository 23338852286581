import './Footer.scss';

const Footer = (props) => {

  return (
    <footer className="footer">
      <p className="footer-title"><img src={`${process.env.PUBLIC_URL}/img/cart-icon.svg`} className="cart-icon" alt="cartIcon" />ATHLETA Quick order System</p>
      <div className="footer-menu">
        <a href="https://www.athleta.co.jp/" target="_blank" rel="noreferrer">アスレタ公式HP</a>
        <a href="https://shop.athleta.co.jp/" target="_blank" rel="noreferrer">アスレタオンラインショップ</a>
        <a href="https://shop.athleta.co.jp/policies/legal-notice" target="_blank" rel="noreferrer">特定商取引法に基づく表記</a>
        <a href="https://shop.athleta.co.jp/policies/privacy-policy" target="_blank" rel="noreferrer">プライバシーポリシー</a>
        <a href="https://shop.athleta.co.jp/pages/contact" target="_blank" rel="noreferrer">お問い合わせ</a>
      </div>
    </footer>
  );
}

export default Footer
