import './InfoBox.scss';
const InfoBox = (props) => {
  return (
    <div className="info-box">
      <div className="tel-info">
        <p className="tel"><a href="tel:0120095403"><span>0120-095-403</span></a></p>
        <p className="time">営業時間：10時〜18時/土日・祝日除く</p>
      </div>
      <a href="https://shop.athleta.co.jp/pages/contact" target="_blank" rel="noreferrer"
         className="info-link">お問い合わせフォーム</a>
    </div>
  );
}

export default InfoBox
