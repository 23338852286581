import './GenerateUrlModal.scss';
import { useEffect, useState } from 'react';
import { generateUrl } from '../../utils';

const GenerateUrlModal = (props) => {
  const [isCopyed, setIsCopyed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [shortenedUrl, setShortenedUrl] = useState(null);
  const copyUrl = () => {
    navigator.clipboard.writeText(shortenedUrl);
    setIsCopyed(true);
  };
  const fbShareUrl = () => {
    return `http://www.facebook.com/share.php?u=${shortenedUrl}`;
  };
  const xShareUrl = () => {
    const text = `[ATHLETA] アスレタクイックシステムでデザインしました！デザインしたユニホームはこちらです！→ ${shortenedUrl}`;
    return `https://twitter.com/intent/tweet?text=${text}`;
  };
  const lineShareUrl = () => {
    const text = '[ATHLETA] アスレタクイックシステムでデザインしました！';
    return `http://line.me/R/msg/text/?${shortenedUrl}%0a${text}`;
  };

  useEffect(() => {
    if (props.modalStatus) {
      setLoading(true);
      generateUrl(props.item).then((res) => {
        setShortenedUrl(res);
        setLoading(false);
      });
    }
  }, [props.modalStatus, props.item]);

  if (!props.modalStatus) return null;

  return (
    <>
      <div className="generate-url-modal-bg" onClick={() => props.closeModal()}></div>
      <div className="generate-url-modal">
        <div className="modal-title">
          <img
            src={`${process.env.PUBLIC_URL}/img/logo_white.png`}
            className="modal-title-logo"
            alt="modalTitleLogo"
          />
          Quick order System
        </div>
        <div className="modal-content">
          <div className="shortened-url">
            {loading ? (
              <i className="loading-icon" />
            ) : shortenedUrl === 'error' ? (
              <p className="text-align-c red-color fs10 mt10" href={shortenedUrl}>
                URL発行に失敗しました。
              </p>
            ) : (
              <a className="shortened-url-link" href={shortenedUrl}>
                {shortenedUrl}
              </a>
            )}
          </div>
          <button className="copy-btn" onClick={() => copyUrl()}>
            <img
              src={`${process.env.PUBLIC_URL}/img/modal/copy_icon.png`}
              className="copy-icon"
              alt="copyIcon"
            />
            コピー
          </button>
          <p className="copy-complete-msg text-align-c fs10 mt10">
            {isCopyed && 'クリップボードにURLをコピーしました。'}
          </p>
          <div className="share-btns">
            <a
              className="share-btn"
              href="mailto:info@athleta.co.jp"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/modal/mail.png`}
                alt="mailIcon"
              />
            </a>
            <a
              className="share-btn"
              href={fbShareUrl()}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/modal/fb.png`}
                alt="fbIcon"
              />
            </a>
            <a
              className="share-btn"
              href={lineShareUrl()}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/modal/line.png`}
                alt="lineIcon"
              />
            </a>
            <a
              className="share-btn"
              href={xShareUrl()}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/modal/x.png`}
                alt="xIcon"
              />
            </a>
          </div>
        </div>
        <button
          className="modal-close-btn"
          onClick={() => props.closeModal(() => setIsCopyed(false))}
        >
          閉じる
        </button>
      </div>
    </>
  );
};

export default GenerateUrlModal
