import Header from './Header';
import Main from './Main';
import Footer from './Footer';
import {Helmet} from "react-helmet";

const App = () => {
  return (
    <>
      <Helmet
        meta={[
          { property: "og:image", content: `${process.env.REACT_APP_URL}/img/athletaorg.png` },
          { property: "og:url", content: `${process.env.REACT_APP_URL}` },
        ]}
        link={[
          { rel: "canonical", href: `${process.env.REACT_APP_URL}` },
        ]}
      >
      </Helmet>
      <Header/>
      <Main/>
      <Footer/>
    </>
  );
}

export default App
