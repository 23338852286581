import { COLOR_CODE_BY_ID } from "../constants";

const athletaLogoSvg = (props) => {
  return (
    <svg id="グループ_3990" data-name="グループ 3990" xmlns="http://www.w3.org/2000/svg" width="151.787" height="39.38" viewBox="0 0 151.787 39.38">
      <defs>
        <clipPath id="clip-path">
          <rect id="長方形_2048" data-name="長方形 2048" width="151.787" height="39.38" fill="none"/>
        </clipPath>
      </defs>
      <g id="グループ_3989" data-name="グループ 3989" transform="translate(0 0)">
        <path id="パス_3735" data-name="パス 3735" d="M147.7,22.416a4.081,4.081,0,1,0-4.084-4.077,4.087,4.087,0,0,0,4.084,4.077m-3.355-4.077a3.355,3.355,0,1,1,3.355,3.349,3.361,3.361,0,0,1-3.355-3.349M35.747,23.48V39.364h-6.49L29.241,23.48H23.6V18.092H41.5V23.48Zm16.047,7.7v8.18H44.432V18.092h7.362v6.851h5.231V18.092h7.356V39.364H57.025v-8.18Zm23.643,2.847h8.522v5.333H68.4V18.092h7.039Zm19.291-.307h9.321v5.64H87.355V18.092h16.693V23.48H94.727v2.77h8.193v4.736H94.727ZM119.309,23.48V39.364h-6.5V23.48h-5.649V18.092h17.9V23.48ZM11.424,11.181,6.851,14.555,8.864,9.116l-5.125-3.6h6.1L11.663,0,13.13,5.515H19.3l-4.934,3.6,1.259,5.439Zm21.24.048L28.091,14.6,30.1,9.161l-5.122-3.6h6.1L32.9.045,34.37,5.563h6.17L35.6,9.161,36.865,14.6Zm21.658,0L49.746,14.6l2.016-5.439-5.129-3.6h6.1L54.558.045l1.467,5.518H62.2l-4.94,3.6L58.517,14.6ZM145.9,20.482h.93V18.709h.975c.7,0,.738.24.738.863a3.1,3.1,0,0,0,.1.911h1.045v-.121c-.2-.073-.2-.236-.2-.9,0-.844-.2-.987-.569-1.15a1.083,1.083,0,0,0,.7-1.083,1.273,1.273,0,0,0-1.425-1.275H145.9Zm.93-2.537V16.725h1.179c.559,0,.665.358.665.6,0,.451-.243.623-.735.623ZM16.868,39.38h6.311L14.811,18.092H8.369L0,39.38H6.311l1.432-3.659h7.694ZM9.845,30.359l1.745-4.438,1.741,4.438ZM141.869,39.38h6.311l-8.369-21.288h-6.442L125,39.38h6.308l1.435-3.659h7.691Zm-7.02-9.021,1.738-4.438,1.742,4.438Z" transform="translate(0 0)" fill={`#${COLOR_CODE_BY_ID[props.color]}`}/>
      </g>
    </svg>
  )
}

export default athletaLogoSvg
