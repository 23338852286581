import { BITLY_API_ACCESS_TOKEN, BITLY_API_URL, LOCALIZED_BY_NAME, COLOR_NAME_BY_ID, COLOR_NAME_FOR_BB_BY_ID, ITEM_SETTINGS_BY_CATEGORY } from "./constants";
import find from 'lodash/find';
import axios from 'axios';
import pako from 'pako';

export const price = (price) => {
  return price.toLocaleString();
};

export const categoryToItemType = (category) => {
  return category.replace(/\d+/, "");
}

export const localized = (name) => {
  return LOCALIZED_BY_NAME[name] || name;
}

export const colorNameByCategory = (category, colorId) => {
  if (category === "bb") {
    return COLOR_NAME_FOR_BB_BY_ID[colorId];
  }
  return COLOR_NAME_BY_ID[colorId];
}

export const checkJapaneseText = (fontId, text) => {
  const japaneseRegex = /[ぁ-んァ-ヶー一-龯]+/;
  if (fontId === "AR-12") { return text; }
  if (text.match(japaneseRegex)) {
    text = "";
  }
  return text;
}

export const checkOnlyNumber = (text) => {
  if (!text.match(/\d+/)) {
    text = "";
  }
  return text;
}

export const isSp = () => {
  return window.outerWidth < 959;
}

export const findItemSetting = (itemType, id) => {
  return find(ITEM_SETTINGS_BY_CATEGORY[itemType], (handle) => handle.handle === id);
}

const normalizeUrl = (savedItem) => {
  const url = new URL(process.env.REACT_APP_URL);
  url.searchParams.set('data', compressParams(savedItem));
  return url;
}

export const compressParams = (savedItem) => {
  const flatObj = (obj, prefix = "") => {
    const flattedObj = {};
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (typeof value === 'object' && value !== null) {
        if (value.id && String(value.order) !== 'true') return;
        const preKey = prefix + key + '_';
        Object.assign(flattedObj, flatObj(value, preKey));
      } else {
        const newKey = prefix + key;
        flattedObj[newKey] = value;
      }
    });
    return flattedObj;
  }
  const compressed = pako.gzip(JSON.stringify(flatObj(savedItem)));
  return btoa(String.fromCharCode.apply(null, compressed));
}

export const generateUrl = async (savedItem) => {
  const url = normalizeUrl(savedItem);
  try {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${BITLY_API_ACCESS_TOKEN}`,
      },
    };
    const params = {
      long_url: url.toString(),
    };

    const res = await axios.post(BITLY_API_URL, params, options);
    return res.data.link;
  } catch (e) {
    console.log(e.response.body);
    return "error";
  }
}
