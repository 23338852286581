import './ConfirmationModal.scss';
import {COLOR_NAME_BY_ID, LOCAL_STORAGE_ENCODED_SAVED_ITEM_KEY, LOCAL_STORAGE_ORDER_FORM_KEY} from '../../constants';
import {compressParams, generateUrl, localized} from '../../utils';
import { numberPositions, displayNumberPosition, sortNamePositions } from '../../orderFormUtils';
import axios from 'axios';
import { useState } from 'react';
import get from 'lodash/get';

const ConfirmationModal = (props) => {
  const [loading, setLoading] = useState(false);
  const goToStore = async () => {
    setLoading(true)
    const items = [];

    props.orderForms.forEach((orderForm, i) => {
      if (!orderForm.sku.variantId) {
        //skuが見つからない場合はスキップ
        alert(`${orderForm.sku.skuKey}:SKUが見つかりませんでした。`);
        return;
      }
      let item = {
        id: orderForm.sku.variantId,
        quantity: orderForm.quantity,
      }
      const properties = makeShopifyProperties(orderForm);
      if (Object.keys(properties).length > 0) {
        item.properties = properties;
      }
      items.push(item);
    });
    const reproductionUrl = await generateUrl(props.item);
    if (reproductionUrl === "error") {
      alert("再現URLの取得に失敗しました。");
      setLoading(false);
      return;
    }

    //APIに登録する
    const options = {
      headers: {
        "Content-Type": "application/json",
      }
    };
    const params = {
      items: items,
      reproductionUrl: reproductionUrl
    };
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/createCustomizeOrder`, params, options)
                           .catch(() => {return null;});
    if (res && get(res, 'data.accessKey')) {
      localStorage.setItem(LOCAL_STORAGE_ENCODED_SAVED_ITEM_KEY, compressParams(props.item))
      localStorage.setItem(LOCAL_STORAGE_ORDER_FORM_KEY, JSON.stringify(props.orderForms));
      window.location.href = `${process.env.REACT_APP_STORE_URL}/cart?accessKey=${res.data.accessKey}`;
    } else {
      alert("決済画面の取得に失敗しました。");
      setLoading(false);
      return;
    }
  }

  const makeShopifyProperties = (orderForm) => {
    const properties = {};
    if (Object.keys(numberPositions(orderForm.item)).length > 0) {
      properties["ナンバー表示位置"] = displayNumberPosition(orderForm.item);
      properties["ナンバータイプ"] = orderForm.item.numberId;
      properties["ナンバーカラー"] = COLOR_NAME_BY_ID[orderForm.item.numberColor];
      orderForm.numbers.forEach((number, i) => {
        properties[`ナンバー${i+1}`] = number;
      });
    }
    const namePositions = orderForm.item.namePosition;
    if (namePositions && Object.keys(namePositions).length > 0) {
      sortNamePositions(namePositions).forEach((namePosition, i) => {
        if (namePosition.kind === "teamName") {
          //全てのnamePosition.kindがteamNameの、全てのnamePosition.positionを取得
          properties[`チーム名表示位置`] = Object.values(namePositions).filter(namePosition => namePosition.kind === "teamName").map((namePosition) => localized(namePosition.position)).join('/');
          properties[`${localized(namePosition.position)} | チーム名上段テキスト`] = namePosition.upperText;
          properties[`${localized(namePosition.position)} | チーム名下段テキスト`] = namePosition.lowerText;
          properties[`${localized(namePosition.position)} | チーム名フォント`] = namePosition.fontId;
          properties[`${localized(namePosition.position)} | チーム名カラー`] = COLOR_NAME_BY_ID[namePosition.color];
        } else if (namePosition.kind === "personalName") {
          properties[`個人名表示位置`] = Object.values(namePositions).filter(namePosition => namePosition.kind === "personalName").map((namePosition) => localized(namePosition.position)).join('/');
          orderForm.personalNames[namePosition.position].forEach((name, i) => {
            properties[`${localized(namePosition.position)} | 個人名${i+1}`] = name;
          });
          properties[`${localized(namePosition.position)} | 個人名フォント`] = namePosition.fontId;
          properties[`${localized(namePosition.position)} | 個人名カラー`] = COLOR_NAME_BY_ID[namePosition.color];
        } else if (namePosition.kind === "athletaLogo") {
          properties[`アスレタロゴ表示位置`] = Object.values(namePositions).filter(namePosition => namePosition.kind === "athletaLogo").map((namePosition) => localized(namePosition.position)).join('/');
          properties[`${localized(namePosition.position)} | アスレタロゴカラー`] = COLOR_NAME_BY_ID[namePosition.color];
        } else if (namePosition.kind === "emblem") {
          properties[`エンブレム表示有無`] = "有";
        }
      })
    }
    if (orderForm.item.isSponsorLogoOption) {
      properties["オリジナルスポンサーロゴ"] = "有";
    }

    if (orderForm.note) {
      properties["備考"] = orderForm.note;
    }
    return properties;
  }

  if (!props.modalStatus) return null;

  return (
    <>
      <div className="confirmation-modal">
        <div className="modal-bg" onClick={() => props.closeModal()}></div>
        <div className="modal">
          <button className="modal-close-icon" onClick={() => props.closeModal()}></button>
          <div className="modal-content">
            これより先はShopifyカートに進みます。<br/>
            注文フォームで入力したナンバー・個人名・備考などは<br/>
            カートでの編集ができません。カートに進んでよろしいですか？<br/>
          </div>
          <div className="modal-btns">
            <button className="modal-close-btn cancel" onClick={() => props.closeModal()}>いいえ</button>
            {loading ? (
              <button className="loading-btn"><i className="loading-icon" /></button>
            ) : (
              <button className="modal-close-btn" onClick={() => goToStore()}>はい</button>
            )}
          </div>
        </div>
      </div>
    </>
  );

};

export default ConfirmationModal
