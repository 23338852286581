import { useState } from 'react';

const useModal = () => {
  const [modalStatus, setModalStatus] = useState(false);

  const openModal = async (callback) => {
    if (callback) {
      await callback();
    }
    setModalStatus(true);
  };

  const closeModal = async (callback) => {
    setModalStatus(false);
    if (callback) {
      await callback();
    }
  };

  return {
    modalStatus,
    closeModal,
    openModal,
  };
}

export default useModal
