import OrderFormName from './OrderFormName';
import { colorNameByCategory, price, checkOnlyNumber, categoryToItemType, findItemSetting } from '../../utils';
import { numberPositions, displayNumberPosition, sortNamePositions, findSku} from "../../orderFormUtils";
import { COLOR_NAME_BY_ID } from '../../constants';
import set from 'lodash/set';
import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';

const OrderFormRow = (props) => {
  const orderItem = props.orderForm.item;
  const namePositionContent = props.orderForm.item.namePosition;

  const updateRow = (type) => {
    if (type === "add") {
      const clone = cloneDeep(props.orderForm);
      clone.quantity = 1;
      clone.numbers = [""];
      clone.personalNames = {backTop: [""], backBottom: [""]};
      props.orderForms.splice(props.index, 0, clone);
      props.setOrderForms(cloneDeep(props.orderForms));
    } else if (type === "delete") {
      props.orderForms.splice(props.index, 1);
      if (findIndex(props.orderForms, (f) => f.category === props.orderForm.category) < 0) {
        set(props.item, `${props.orderForm.category}.order`, false)
        props.itemDispatch({ type: 'update', item: props.item });
      }
      props.setOrderForms(cloneDeep(props.orderForms));
    }
  }

  const updateQuantity = (type) => {
    if (type === "add") {
      props.orderForm.quantity += 1;
      props.orderForm.numbers.push("");
      props.orderForm.personalNames.backTop.push("");
      props.orderForm.personalNames.backBottom.push("");
    } else if (type === "subtract" && props.orderForm.quantity !== 1) {
      props.orderForm.quantity -= 1;
      props.orderForm.numbers.pop();
      props.orderForm.personalNames.backTop.pop();
      props.orderForm.personalNames.backBottom.pop();
    }
    props.setOrderForms(cloneDeep(props.orderForms));
  }

  const updateSize = (size) => {
    set(props.orderForm, "size", size);
    set(props.orderForm, "sku", findSku(props.orderForm.category, props.orderForm.id, size, props.orderForm.item.color));
    props.setOrderForms(cloneDeep(props.orderForms));
  }

  const updateOrderForm = (attr, value) => {
    set(props.orderForm, attr, value);
    props.setOrderForms(cloneDeep(props.orderForms));
  }

  const orderPrice = () => {
    return price(props.orderForm.sku.price * props.orderForm.quantity);
  }

  const updateNumber = (index, value) => {
    props.orderForm.numbers[index] = checkOnlyNumber(value);
    updateOrderForm("numbers", props.orderForm.numbers);
  }

  const SizeOptions = () => {
    const itemSetting = findItemSetting(categoryToItemType(props.orderForm.category), props.orderForm.id);
    return itemSetting.sizes.map((size, i) => <option value={size} key={i}>{size}</option>);
  }

  const isDisplayNumber = () => {
    return orderItem.isNumber && Object.keys(numberPositions(orderItem)).length;
  }

  return (
    <tr className="order-form-table-body-row">
      <td className="product-name">
        <p className="line-height-1-5">{props.orderForm.item.name}</p>
        <p className="line-height-1-5">{props.orderForm.id}</p>
      </td>
      <td className="product-size text-align-c">
        <select value={props.orderForm.size} onChange={(e) => updateSize(e.target.value)}>
          <SizeOptions />
        </select>
      </td>
      <td className="product-quantity text-align-c">
        <div className="product-quantity-inner">
          <button className="quantity-subtract" onClick={(e) => updateQuantity("subtract")}>−</button>
          <input type="number" value={props.orderForm.quantity} min="1" readOnly />
          <button className="quantity-add" onClick={(e) => updateQuantity("add")}>＋</button>
        </div>
      </td>
      <td className="product-detail">
        <div className="product-detail-inner">
          <div className="product-detail-content">
            <p className="line-height-1-5">カラー：{colorNameByCategory(props.orderForm.category, orderItem.color)}</p>
            <p className="mt5 line-height-1-5">ナンバー表示有無：{ isDisplayNumber() ? "有": "無"}</p>
            { !!isDisplayNumber() && (
              <>
                <p className="mt5 line-height-1-5">ナンバー表示位置：{displayNumberPosition(orderItem)}</p>
                <p className="mt5 line-height-1-5">ナンバーフォント：{orderItem.numberId}</p>
                { props.orderForm.numbers.map((number, i) => {
                  return (
                    <p className="mt5 line-height-1-5" key={i}>
                      {`ナンバー${i+1}：`}
                      <input
                        type="number"
                        className="order-form-number-input"
                        value={number}
                        onChange={(e) => {if (e.target.value.length <= 2) {updateNumber(i, e.target.value)}}}
                        maxLength={2}
                      />
                    </p>
                  )
                })}
                <p className="mt5 line-height-1-5">ナンバーカラー：{COLOR_NAME_BY_ID[orderItem.numberColor]}</p>
              </>
            )}
            { orderItem.isName && namePositionContent && Object.keys(namePositionContent).length > 0 && (
              sortNamePositions(namePositionContent).map((namePosition, i) => {
                return (
                  <OrderFormName
                    key={i}
                    namePosition={namePosition}
                    orderForm={props.orderForm}
                    updateOrderForm={updateOrderForm}
                  />
                )
              })
            )}
            { orderItem.isSponsorLogoOption && (
              <p className="mt5 line-height-1-5">【オプション】オリジナルスポンサーロゴ表示有無：有</p>
            )}
          </div>
          <div className="product-detail-note">
            <p className="mt5 mr10 product-detail-note-title">備考</p>
            <div className="note-input">
              <textarea
                name="note"
                defaultValue={props.orderForm.note}
                rows={10}
                onChange={(e) => updateOrderForm("note", e.target.value)}
              />
            </div>
          </div>
        </div>
      </td>
      <td className="product-price">￥{orderPrice()}</td>
      <td className="action text-align-c">
        <button className="add-btn" onClick={(e) => updateRow("add")}>＋</button>
        <br/>
        <button className="delete-btn mt10" onClick={(e) => updateRow("delete")}>−</button>
      </td>
    </tr>
  );
}

export default OrderFormRow
