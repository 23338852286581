import './SizeChartModal.scss';
import { SIZE_TABLE_ROWS_BY_CATEGORY } from '../../constants';

const SizeTableRow = (props) => {
  return (
    props.data.map((text, i) => {
      if (i === 0) {
        return <th key={i}>{text}</th>;
      } else {
        return <td key={i}>{text}</td>;
      }
    })
  );
}

const SizeChartModal = (props) => {

  return (
    <div className="size-chart">
      <div className="measure-size">
        <div className="tops">
          <img src={`${process.env.PUBLIC_URL}/img/modal/size_tops_img.png`} alt="sizeTopsImgImg" />
        </div>
        <div className="bottoms">
          <img src={`${process.env.PUBLIC_URL}/img/modal/size_bottoms_img.png`} alt="sizeBottomsImg" />
        </div>
      </div>
      <div className="item-content">
        <div className="item-title">ゲームシャツ（トップス）</div>
        <div className="item-imgs">
          <div className="item-img">
            <p className="item-img-name">セットイン丸首</p>
            <img src={`${process.env.PUBLIC_URL}/img/modal/tops.svg`} alt="tops" />
          </div>
          <div className="item-img">
            <p className="item-img-name">セットインV首</p>
            <img src={`${process.env.PUBLIC_URL}/img/modal/tops_v.svg`} alt="topsV" />
          </div>
        </div>
      </div>
      <div className="size-table-wrapper">
        <table className="size-table">
          <tbody>
            { SIZE_TABLE_ROWS_BY_CATEGORY.t.map((row, i) => {
              return <tr key={i}><SizeTableRow data={row} /></tr>;
            })}
          </tbody>
        </table>
      </div>
      <div className="item-content">
        <div className="item-title">ゲームパンツ</div>
        <div className="item-imgs">
          <div className="item-img">
            <img src={`${process.env.PUBLIC_URL}/img/modal/pants.png`} alt="pants" />
          </div>
        </div>
      </div>
      <div className="size-table-wrapper">
        <table className="size-table">
          <tbody>
            { SIZE_TABLE_ROWS_BY_CATEGORY.p.map((row, i) => {
              return <tr key={i}><SizeTableRow data={row} /></tr>;
            })}
          </tbody>
        </table>
      </div>
      <div className="item-content">
        <div className="item-title">ビブス</div>
        <div className="item-imgs">
          <div className="item-img">
            <p className="item-img-name">片面ビブス</p>
            <img src={`${process.env.PUBLIC_URL}/img/modal/bibs.svg`} alt="bibs" />
          </div>
          <div className="item-img">
            <p className="item-img-name">両面ビブス（リバーシブルビブス）</p>
            <img src={`${process.env.PUBLIC_URL}/img/modal/bibs_both.svg`} alt="bibsBoth" />
          </div>
        </div>
      </div>
      <div className="size-table-wrapper">
        <table className="size-table">
          <tbody>
            { SIZE_TABLE_ROWS_BY_CATEGORY.b.map((row, i) => {
              return <tr key={i}><SizeTableRow data={row} /></tr>;
            })}
          </tbody>
        </table>
      </div>
      <div className="item-content">
        <div className="item-title">プラクティスシャツ</div>
        <div className="item-imgs">
          <div className="item-img">
            <img src={`${process.env.PUBLIC_URL}/img/modal/practice_shirts.png`} alt="practiceShirts" />
          </div>
        </div>
      </div>
      <div className="size-table-wrapper">
        <table className="size-table">
          <tbody>
            { SIZE_TABLE_ROWS_BY_CATEGORY.ps.map((row, i) => {
              return <tr key={i}><SizeTableRow data={row} /></tr>;
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SizeChartModal
