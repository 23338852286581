import './Modal.scss';

const Modal = (props) => {
  if (!props.modalStatus) return null;

  return (
    <>
      <div className="modal-bg" onClick={() => props.closeModal()}></div>
      <div className="modal">
        <div className="modal-title">{props.modalTitle}</div>
        <button className="modal-close-icon" onClick={() => props.closeModal()}></button>
        <div className="modal-content">{props.children}</div>
        <button className="modal-close-btn" onClick={() => props.closeModal()}>閉じる</button>
      </div>
    </>
  );
};

export default Modal
