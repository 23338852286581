import './Simulator.scss';
import { useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import get from 'lodash/get';
import set from 'lodash/set';
import difference from 'lodash/difference'
import parse from 'html-react-parser';
import CategoryContent from './simulator/CategoryContent';
import Mannequin from './simulator/Mannequin';
import ItemSelectModal from './modals/ItemSelectModal';
import { categoryToItemType, findItemSetting, isSp } from '../utils';
import {
  CATEGORY_BTNS,
  MANNEQUIN_UPPER_ITEM_BY_CATEGORY_TYPES,
  MANNEQUIN_LOWER_ITEM_BY_CATEGORY_TYPES
} from '../constants';
import { numberPositions } from '../orderFormUtils';
import camelCase from 'lodash/camelCase';
import isEmpty from "lodash/isEmpty";
import useModal from '../hooks/useModal';

const ItemCategoryBtn = (props) => {
  const itemCategoryClass = () => {
    let klass = "item-category";
    if (props.order) {
      klass += " order"
    }
    if (props.editing === props.category) {
      klass += " editing"
    }
    return klass;
  }

  return (
    <>
      <button className={itemCategoryClass()} onClick={() => props.updateEditing(props.category)}>
        { props.order && (
          <div className="category-status-icon order-icon">注文対象</div>
        )}
        { props.editing === props.category && !props.order && (
          <div className="category-status-icon editing-icon">編集中</div>
        )}
        <div className="item-category-name-pc">
          {parse(props.name)}
        </div>
        <img src={`${process.env.PUBLIC_URL}/img/simulator/${props.imgPath}`} alt="itemCategoryIcon" />
        <div className="item-category-name-sp">
          {parse(props.name)}
        </div>
      </button>
    </>
  );
}

const CategoryOrder = (props) => {
  if (!props.editing) return null;

  return (
    <>
      <div className="category-order-check">
        {props.item[props.editing].id && (
          <>
            <input
              type="checkbox"
              className="category-order-checkbox"
              id="checkOrder"
              checked={Boolean(props.item[props.editing].order)}
              onChange={(e) => props.updateItem(props.editing, "order", e.target.checked)}
            />
            <label htmlFor="checkOrder" className="category-order-check-label">このアイテムのデザインを注文する</label>
            <p className="category-order-desc fs10">※注文対象にしない場合はチェックを外してください。</p>
          </>
        )}
      </div>
      <button
        className="category-order-select-item fs12"
        onClick={() => props.itemSelectModalHooks.openModal()}
      >
        このアイテムの商品画像から選ぶ
      </button>
      <ItemSelectModal
        modalStatus={props.itemSelectModalHooks.modalStatus}
        closeModal={props.itemSelectModalHooks.closeModal}
        editing={props.editing}
        item={props.item}
        itemDispatch={props.itemDispatch}
      />
    </>
  )
}

const initPrevEditing = (categoryTypes, item) => {
  Object.keys(item).find((key) => categoryTypes.includes(categoryToItemType(key)) && !isEmpty(item[key]))
}

const Simulator = (props) => {
  const [direction, setDirection] = useState("F");
  const [withBody, setWithBody] = useState(true);
  const [editing, setEditing] = useState("t1");
  const [prevEditingUpper, setPrevEditingUpper] = useState(initPrevEditing(MANNEQUIN_UPPER_ITEM_BY_CATEGORY_TYPES, props.item));
  const [prevEditingLower, setPrevEditingLower] = useState(initPrevEditing(MANNEQUIN_LOWER_ITEM_BY_CATEGORY_TYPES, props.item));
  const [openSim, setOpenSim] = useState(!isSp());
  const sliderSettings = {
    variableWidth: true,
    infinite: false,
    swipe: true,
    speed: 500,
  };

  const toggleDirection = () => {
    if(direction === 'F') {
      setDirection('B');
    } else {
      setDirection('F');
    }
  };

  const updateDirection = (direction) => {
    setDirection(direction);
  }

  const toggleWithBody = () => {
    setWithBody(!withBody);
  }

  const toggleOpenSim = (value) => {
    if (value) {
      window.scroll({top: 0});
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    setOpenSim(value);
  }

  const resetSim = () => {
    props.resetDesign();
    setEditing(null);
  }

  const updateEditing = (category) => {
    if (editing && category !== editing && get(props.item, `${editing}.id`)) {
      const itemType = categoryToItemType(editing);
      if (MANNEQUIN_UPPER_ITEM_BY_CATEGORY_TYPES.includes(itemType)) {
        setPrevEditingUpper(editing);
      } else if (MANNEQUIN_LOWER_ITEM_BY_CATEGORY_TYPES.includes(itemType)) {
        setPrevEditingLower(editing);
      }
    }
    if (category === "so" && isEmpty(props.item[category])) {
      props.item[category] = {id: "SOX-001", color: "10"};
      props.itemDispatch({ type: 'update', item: props.item });
    }
    setEditing(category);
  }

  const updateItem = (category, attr, val) => {
    set(props.item, `${category}.${attr}`, val)
    if (attr === "id") {
      const itemSetting = findItemSetting(categoryToItemType(category), val);
      set(props.item, `${category}.name`, get(itemSetting, "handleJp"));
      set(props.item, `${category}.color`, String(get(itemSetting, "colors")[0]));
      if (itemSetting.numberTypeParams) {
        set(props.item, `${category}.isNumber`, true);
        set(props.item, `${category}.numberId`, get(itemSetting, "numberTypeParams.fonts")[0]);
        set(props.item, `${category}.numberColor`, String(get(itemSetting, "numberTypeParams.colors")[0]));
        const positions = itemSetting.numberTypeParams.position.map((position) => camelCase(`isNumber ${position}`));
        for (const isNumberPosition of Object.keys(numberPositions(get(props.item, category)))) {
          if (!positions.includes(isNumberPosition)) {
            set(props.item, `${category}.${isNumberPosition}`, false);
          }
        }
      }
      if (itemSetting.namePositionParams) {
        set(props.item, `${category}.isName`, true);
        const namePositions = Object.keys(get(props.item, `${category}.namePosition`) || []);
        const itemNamePositions = Object.keys(itemSetting.namePositionParams || []);
        const diffPositions = difference(namePositions, itemNamePositions);
        for (const diffPosition of diffPositions) {
          set(props.item, `${category}.namePosition.${diffPosition}`, {});
        }
      }
    }
    props.itemDispatch({ type: 'update', item: props.item });
  }

  const itemSelectModalHooks = useModal();

  return (
    <div id="simulatorArea" className="simulator-area">
      <div id="mannequinArea" className="mannequin-area">
        <div className="mannequin-inner">
          <div id="mannequinBtns" className="mannequin-btns">
            <button
              type="button"
              id="openMaterialModalBtn"
              className="mannequin-btn"
              onClick={() => props.openMaterialModal()}
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/material.svg`}
                alt="materialBtn"
              />
            </button>
            <button
              type="button"
              id="openSizeChartModalBtn"
              className="mannequin-btn"
              onClick={() => props.openSizeChartModal()}
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/size_chart.svg`}
                alt="sizeChartBtn"
              />
            </button>
            <button
              type="button"
              className="mannequin-btn"
              onClick={() => props.openGenerateUrlModal()}
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/generate_url.svg`}
                alt="generateUrlBtn"
              />
            </button>
            <button
              type="button"
              className="mannequin-btn"
              onClick={() => resetSim()}
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/design_reset.svg`}
                alt="designResetBtn"
              />
            </button>
            <button
              type="button"
              className="front-back-btn"
              onClick={() => toggleDirection()}
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/front_back.svg`}
                alt="FrontBackBtn"
              />
            </button>
            <button
              type="button"
              className="body-toggle-btn only-sp"
              onClick={() => toggleWithBody()}
            >
              {withBody ? 'BODY OFF' : 'BODY ON'}
            </button>
          </div>
          <Mannequin
            direction={direction}
            withBody={withBody}
            item={props.item}
            editing={editing}
            prevEditingUpper={prevEditingUpper}
            prevEditingLower={prevEditingLower}
          />
        </div>
        <div id="mannequinBottom" className="mannequin-bottom">
          <button
            type="button"
            className="body-toggle-btn only-pc"
            onClick={() => toggleWithBody()}
          >
            {withBody ? 'BODY OFF' : 'BODY ON'}
          </button>
          <p className="mannequin-bottom-annotation">
            ※ソックスは色を変更してもアバターに反映されませんのでご了承ください。
          </p>
        </div>
      </div>
      <div className="simulator-open-btn-area only-sp">
        <p className="yellow-color fs10 text-align-c">アイテムやマーク加工を選択してオリジナルデザインを作成しよう！</p>
        <button
          id="openSimBtn"
          className="simulator-open-btn yellow-color mt10 fs14"
          onClick={() => toggleOpenSim(true)}
        >
          オリジナルデザインを作成する
        </button>
      </div>
      { openSim && (
        <>
          <div id="simulator" className="simulator">
            <div className="item-category-area">
              <div className="fs14">Item category</div>
              <p className="fs10 mt5 line-height-1-5">
                アイテムを選択の上、型番やカラーをお選びください。トップスとパンツは一度に2つのデザインを注文可能です。
              </p>
              <Slider className="only-pc" {...sliderSettings}>
                {CATEGORY_BTNS.map((categoryBtn, i) => {
                  return (
                    <ItemCategoryBtn
                      name={categoryBtn.name}
                      imgPath={categoryBtn.imgPath}
                      category={categoryBtn.category}
                      editing={editing}
                      updateEditing={updateEditing}
                      order={get(props.item,`${categoryBtn.category}.order`)}
                      key={i}
                    />
                  );
                })}
              </Slider>
              <div className="item-category-btns only-sp">
                {CATEGORY_BTNS.map((categoryBtn, i) => {
                  return (
                    <ItemCategoryBtn
                      name={categoryBtn.name}
                      imgPath={categoryBtn.imgPath}
                      category={categoryBtn.category}
                      editing={editing}
                      updateEditing={updateEditing}
                      order={get(props.item,`${categoryBtn.category}.order`)}
                      key={i}
                    />
                  );
                })}
              </div>
            </div>
            <div className="category-content">
              <CategoryContent
                editing={editing}
                item={props.item}
                updateItem={updateItem}
                updateDirection={updateDirection}
              />
            </div>
            <div className="category-order">
              <CategoryOrder
                editing={editing}
                updateItem={updateItem}
                item={props.item}
                itemDispatch={props.itemDispatch}
                itemSelectModalHooks={itemSelectModalHooks}
              />
            </div>
            <div className="simulator-close-area only-sp">
              <div className="simulator-close-border">
                <button
                  type="button"
                  className="simulator-close-btn yellow-color fs14"
                  onClick={() => toggleOpenSim(false)}
                >
                  閉じる
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Simulator
