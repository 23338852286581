import './ColorSelector.scss';
import get from 'lodash/get';
import { COLORS_FOR_BB_BY_ID, COLOR_NAME_BY_ID } from '../../constants';
import { colorNameByCategory } from '../../utils';
import ColorButton from './ColorButton';
import MultiColorButton from './MultiColorButton'

const ColorSelector = (props) => {
  const selectedColorId = get(props.item[props.editing], props.attr);
  const colorName = () => {
    if (props.editing === "bb" && props.attr === "color") {
      return colorNameByCategory("bb", selectedColorId);
    }
    return COLOR_NAME_BY_ID[selectedColorId];
  }
  return (
    <>
      <div className="fs14 mt10">
        {`Color (全${props.colors.length}色) / `}
        <span className="yellow-color">{colorName()}</span>
      </div>
      { props.editing === "bb" && (props.attr === "numberColor") && (
        <p className="mt5 fs10 yellow-color">表面と裏面、それぞれ同じフォントカラーになりますので、視認性を考慮してカラーをお選びください</p>
      )}
      <div className="category-item-colors d-flex-wrap">
        {props.colors.map((color, i) => {
          if (props.editing === "bb" && (props.attr === "color")) {
            const [frontColor, backColor] = COLORS_FOR_BB_BY_ID[color];
            return (
              <MultiColorButton
                type="button"
                className={`category-item-multi-color ${selectedColorId === color ? "selected" : ""}`}
                key={i}
                onClick={() => props.updateItem(props.editing, props.attr, color)}
                $background_color_front={frontColor}
                $background_color_back={backColor}
              >
                <div className='front-color' />
                <div className='back-color' />
              </MultiColorButton>
            )
          } else {
            return (
              <ColorButton
                type="button"
                className={`category-item-color ${selectedColorId === color ? "selected" : ""}`}
                key={i}
                onClick={() => props.updateItem(props.editing, props.attr, color)}
                $background_color={color}
              />
            );
          }
        })}
      </div>
    </>
  );
}

export default ColorSelector
