import './ItemSelectModal.scss';
import { useState, useEffect } from 'react';
import { categoryToItemType, findItemSetting } from '../../utils';
import {
  ITEM_COLORS_SETTINGS_BY_CATEGORY,
  COLOR_CODE_BY_ID,
  CATEGORY_NAME_BY_CATEGORY,
  COLORS_FOR_BB_BY_ID
} from '../../constants';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import ColorButton from '../simulator/ColorButton';
import MultiColorButton from '../simulator/MultiColorButton';
import get from 'lodash/get';

const ItemSelectModal = (props) => {
  const itemType = categoryToItemType(props.editing);
  const itemColorSetting = ITEM_COLORS_SETTINGS_BY_CATEGORY[itemType];
  const itemColors = Object.keys(itemColorSetting);
  const [color, setColor] = useState(itemColors[0]);
  const [itemId, setItemId] = useState(null);
  const sliderSettings = {
    variableWidth: true,
    infinite: false,
    swipe: true,
    speed: 500,
  };

  const updateSelectedItem = () => {
    if (color && itemId) {
      const itemSetting = findItemSetting(categoryToItemType(props.editing), itemId);
      const isNumber = !!get(itemSetting, "numberTypeParams");
      props.item[props.editing] = {
        id: itemId,
        color: color,
        name: get(itemSetting, "handleJp"),
        isNumber: isNumber,
        isName: true
      };
      if (isNumber) {
        props.item[props.editing].numberColor = String(get(itemSetting, "numberTypeParams.colors")[0]);
        props.item[props.editing].numberId = get(itemSetting, "numberTypeParams.fonts")[0];
      }
      props.itemDispatch({ type: 'update', item: props.item });
      props.closeModal(closeModalCallback);
    }
  }

  const updateColor = (selectedColor) => {
    setColor(selectedColor);
    setItemId(null);
  }

  const closeModalCallback = () => {
    setItemId(null);
  }

  useEffect(() => {
    const itemType = categoryToItemType(props.editing);
    const itemColorSetting = ITEM_COLORS_SETTINGS_BY_CATEGORY[itemType];
    const itemColors = Object.keys(itemColorSetting);
    setColor(itemColors[0]);
  }, [props.editing])

  if (!props.modalStatus) return null;

  return (
    <>
      <div className="item-select-modal-bg" onClick={() => props.closeModal(closeModalCallback)}></div>
      <div className="item-select-modal">
        <button className="modal-close-icon" onClick={() => props.closeModal(closeModalCallback)}></button>
        <div className="modal-content">
          <div className="item-select-title">バリエーション（{itemColorSetting[color].length}型）</div>
          <div className="item-select-colors">{CATEGORY_NAME_BY_CATEGORY[itemType]} - Color（全{itemColors.length}色）</div>
          <div className="select-colors">
            <Slider {...sliderSettings}>
              {itemColors.map((itemColor, i) => {
                if (props.editing === "bb") {
                  const [frontColor, backColor] = COLORS_FOR_BB_BY_ID[itemColor];
                  return (
                    <MultiColorButton
                      type="button"
                      className={`item-select-multi-color ${itemColor === color ? "selected" : ""}`}
                      key={i}
                      onClick={() => updateColor(itemColor)}
                      $background_color_front={frontColor}
                      $background_color_back={backColor}
                    >
                      <div className='front-color' />
                      <div className='back-color' />
                    </MultiColorButton>
                  )
                } else {
                  return (
                    <ColorButton
                      type="button"
                      className={`item-select-color ${itemColor === color ? "selected" : ""}`}
                      key={i}
                      onClick={() => updateColor(itemColor)}
                      $background_color={itemColor}
                    />
                  )
                }
              })}
            </Slider>
          </div>
          <div className="select-items d-flex-wrap">
            {itemColorSetting[color].map((selectItemId, i) => {
              return (
                <button
                  type="button"
                  className={`d-flex-wrap-item ${itemId === selectItemId ? "selected" : ""}`}
                  key={i}
                  onClick={() => setItemId(selectItemId)}
                >
                  <img src={`${process.env.PUBLIC_URL}/img/colors/${itemType}/${selectItemId}_${COLOR_CODE_BY_ID[color]}.png`} alt={`item-select-${selectItemId}`} />
                </button>
              )
            })}
          </div>
        </div>
        <div className="modal-btns">
          <button className="modal-close-btn" onClick={() => props.closeModal(closeModalCallback)}>閉じる</button>
          <button className="modal-close-btn" onClick={() => updateSelectedItem()}>選択した商品をシュミレーターに反映する</button>
        </div>
      </div>
    </>
  );
};

export default ItemSelectModal
