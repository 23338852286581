import './ItemNamePosition.scss';
import { useState } from 'react';
import { localized, checkJapaneseText } from '../../utils';
import ColorSelector from './ColorSelector';
import get from 'lodash/get';

const NamePosition = (props) => {
  return (
    <div className="item-name-position">
      <div className="item-name-position-btn-area">
        <button
          type="button"
          className={`mr10 item-name-position-btn ${props.editingPosition === props.position ? "selected" : ""}`}
          onClick={() => props.updateEditingPosition(props.position)}
        >
          <img src={`${process.env.PUBLIC_URL}/img/simulator/name/${props.position}.png`} alt={props.position} />
        </button>
        <button
          type="button"
          className="item-name-position-trash-btn"
          onClick={() => props.resetNamePosition(props.position)}
        >
          <img src={`${process.env.PUBLIC_URL}/img/simulator/trash.png`} alt="trashBtn" />
        </button>
      </div>
      <div className="item-name-position-kinds mt5">
        { props.kinds.map((kind, i) => {
          return (
            <div className="item-name-position-kind fs10 mt5" key={i}>
              <input
                type="radio"
                name={props.position}
                id={`${props.position}_${kind}`}
                value={kind}
                checked={get(props.itemContent.namePosition, `${props.position}.kind`) === kind}
                onChange={(e) => props.updateNamePositionKind(props.position, e.target.value)}
              />
              <label className="item-name-position-kind-name" htmlFor={`${props.position}_${kind}`} >
                {localized(kind)}
              </label>
            </div>
          );
        })}
      </div>
      <button
        type="button"
        className="item-name-position-trash-btn only-sp"
        onClick={() => props.resetNamePosition(props.position)}
      >
        <img src={`${process.env.PUBLIC_URL}/img/simulator/trash.png`} alt="trashBtn" />
      </button>
    </div>
  );
}

const ItemNamePosition = (props) => {
  const [editingPosition, setEditingPosition] = useState(null);
  const itemContent = props.item[props.editing];
  const editingNamePosition = get(itemContent.namePosition, editingPosition);

  const updateEditingPosition = (position) => {
    const direction = position.startsWith('front') ? 'F' : 'B';
    props.updateDirection(direction);
    setEditingPosition(position);
  }

  const updateNamePositionKind = (position, kind) => {
    updateEditingPosition(position);
    const namePositionValue = {kind: kind};
    let color = get(editingNamePosition, 'color');
    const namePositionSetting = get(props.itemSetting.namePositionParams, position)
    if (["teamName", "personalName"].includes(kind)) {
      const fontColors = namePositionSetting.fontColors.map(String)
      if (!fontColors.includes(color)) {
        color = fontColors[0];
      }
    }
    namePositionValue.color = color;
    namePositionValue.fontId = get(editingNamePosition, 'fontId') || namePositionSetting.fonts?.[0];
    namePositionValue.upperText = get(editingNamePosition, 'upperText') || "";
    namePositionValue.upperTextSize = get(editingNamePosition, 'updateTextSize') || 100;
    namePositionValue.lowerText = get(editingNamePosition, 'lowerText') || "";
    namePositionValue.lowerTextSize = get(editingNamePosition, 'lowerTextSize') || 100;
    props.updateItem(props.editing, `namePosition.${position}`, namePositionValue);
  }

  const resetNamePosition = (position) => {
    props.updateItem(props.editing, `namePosition.${position}`, {})
  }

  const updateTextSize = (text, value) => {
    props.updateItem(props.editing, `namePosition.${editingPosition}.${text}Size`, value);
  }

  const updateTextSizeAuto = (text, value) => {
    if (!value) return null;
    updateTextSize(text, 100);
  }

  const updateFont = (fontId) => {
    editingNamePosition.fontId = fontId;
    if (editingNamePosition.kind === "teamName") {
      editingNamePosition.upperText = checkJapaneseText(fontId, editingNamePosition.upperText);
      editingNamePosition.lowerText = checkJapaneseText(fontId, editingNamePosition.lowerText);
    }
    props.updateItem(props.editing, `namePosition.${editingPosition}`, editingNamePosition);
  }

  const updateText = (text, value) => {
    props.updateItem(props.editing, `namePosition.${editingPosition}.${text}`, checkJapaneseText(editingNamePosition.fontId, value));
  }

  return (
    <>
      <div className="fs14 white-border-b">
        <input
          type="checkbox"
          className="item-checkbox"
          id="checkIsName"
          checked={Boolean(itemContent.isName)}
          onChange={(e) => props.updateItem(props.editing, "isName", e.target.checked)}
        />
        Name - Position
      </div>
      { itemContent.isName && (
        <>
          <div className="item-name-positions mt10">
            {
              Object.keys(props.itemSetting.namePositionParams).map((namePosition, i) => {
                  return (
                    <NamePosition
                      itemContent={itemContent}
                      position={namePosition}
                      kinds={get(props.itemSetting.namePositionParams, `${namePosition}.kind`)}
                      editingPosition={editingPosition}
                      updateEditingPosition={updateEditingPosition}
                      updateNamePositionKind={updateNamePositionKind}
                      resetNamePosition={resetNamePosition}
                      key={i}
                    />
                  )
              })
            }
          </div>
          <p className="fs10 mt10 line-height-1-5 yellow-color">※エンブレムは決済後にデータ入稿をお願いします。表示位置などは決済後にオペレーターと相談ください。</p>
          { get(editingNamePosition, "kind") === "teamName" && (
            <div className="item-name-texts mt20">
              { ["upperText", "lowerText"].map((text, i) => {
                const textSizeValue = parseInt(get(editingNamePosition, `${text}Size`));
                return (
                  <div className="item-name-text mt10 fs12" key={i}>
                    <div className="mr10">
                      Text：
                      <input
                        type="text"
                        id={text}
                        className="fs12 item-name-text-input"
                        name={text}
                        value={get(editingNamePosition, text) || ""}
                        onChange={(e) => updateText(text, e.target.value)}
                      />
                    </div>
                    <div className="item-name-text-size-wrapper">
                      <div className="item-name-text-size-auto mr10">
                        <input
                          type="checkbox"
                          className="item-checkbox"
                          id={`${text}SizeAutoCheckbox`}
                          name={`${text}SizeAutoCheckbox`}
                          checked={textSizeValue === 100}
                          onChange={(e) => updateTextSizeAuto(text, e.target.value)}
                        />
                        <label className="item-name-text-size-auto-label" htmlFor={`${text}SizeAutoCheckbox`} >
                          お任せサイズ
                        </label>
                      </div>
                      <div className="item-name-text-size">
                        <input
                          type="range"
                          className="item-range"
                          id={`${text}Size`}
                          name={`${text}Size`}
                          value={textSizeValue}
                          min="50"
                          max="200"
                          step="5"
                          onChange={(e) => updateTextSize(text, e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
              <p className="yellow-color mt10 fs10 text-align-r">※プレビューはあくまで目安です。</p>
            </div>
          )}
          { ["teamName", "personalName"].includes(get(editingNamePosition, "kind")) && (
              <div className="item-name-font-area mt20">
                <div className="fs14">
                  {`Font (全${get(props.itemSetting.namePositionParams, `${editingPosition}.fonts.length`)}種類) / `}
                  <span className="yellow-color">{get(editingNamePosition, "fontId")}</span>
                </div>
                <p className="mt5 fs10 yellow-color">
                  ※AR-12以外は日本語入力不可
                </p>
                <div className="d-flex-wrap">
                  { get(props.itemSetting.namePositionParams, `${editingPosition}.fonts`).map((fontId, i) => {
                    return (
                      <button
                        type="button"
                        className={`d-flex-wrap-item ${get(editingNamePosition, "fontId") === fontId ? "selected" : ""}`}
                        key={i}
                        onClick={() => updateFont(fontId)}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/img/simulator/font/${fontId}.png`}
                          alt={fontId}
                        />
                      </button>
                    )
                  })}
                </div>
              </div>
            )
          }
          { ["teamName", "personalName"].includes(get(editingNamePosition, "kind")) && (
              <div className="item-name-color-area mt20">
                <ColorSelector
                  colors={get(props.itemSetting.namePositionParams, `${editingPosition}.fontColors`).map(String)}
                  item={props.item}
                  editing={props.editing}
                  attr={`namePosition.${editingPosition}.color`}
                  updateItem={props.updateItem}
                />
              </div>
            )
          }
          { get(editingNamePosition, "kind") === "athletaLogo" && (
              <div className="item-name-color-area mt20">
                <ColorSelector
                  colors={get(props.itemSetting.namePositionParams, `${editingPosition}.logoColors`).map(String)}
                  item={props.item}
                  editing={props.editing}
                  attr={`namePosition.${editingPosition}.color`}
                  updateItem={props.updateItem}
                />
              </div>
            )
          }
        </>
      )}
    </>
  );
}

export default ItemNamePosition
