import './CategoryContent.scss';
import { price, categoryToItemType, localized, findItemSetting } from './../../utils';
import { findSku } from '../../orderFormUtils';
import { CATEGORY_NAME_BY_CATEGORY, DEFAULT_SIZE_BY_CATEGORY, ITEM_SETTINGS_BY_CATEGORY, JUNIOR_SIZE_BY_CATEGORY } from '../../constants';
import ItemNamePosition from './ItemNamePosition';
import ColorSelector from './ColorSelector';
import get from 'lodash/get';
import camelcase from 'lodash/camelCase'

const CategoryContent = (props) => {
  if (!props.editing) return null;

  const editingItemType = categoryToItemType(props.editing);
  const categoryName = CATEGORY_NAME_BY_CATEGORY[editingItemType];
  const itemIds = ITEM_SETTINGS_BY_CATEGORY[editingItemType].map((handle) => handle.handle);
  const itemContent = props.item[props.editing];
  const itemSetting = findItemSetting(editingItemType, itemContent.id)
  const sku = Object.keys(itemContent).length === 0 ? null : findSku(props.editing, itemContent.id, DEFAULT_SIZE_BY_CATEGORY[editingItemType], itemContent.color);
  const jSku = Object.keys(itemContent).length === 0 ? null : findSku(props.editing, itemContent.id, JUNIOR_SIZE_BY_CATEGORY[editingItemType], itemContent.color);

  const updateNumberPosition = (attr, value) => {
    const direction = attr === "isNumberBack" ? "B" : "F";
    props.updateDirection(direction);
    props.updateItem(props.editing, attr, value);
  };

  return (
    <>
      <div className="category-item-area">
        <div className="fs14 white-border-b">
          {`${categoryName} - Type (全${itemIds.length}型) / `}
          <span className="yellow-color">{itemContent.id}</span>
        </div>
        <p className="category-price mt5 fs12">
          {sku && `¥${price(sku.price)} (税込) `}
          {jSku && `ジュニア:¥${price(jSku.price)} (税込)`}
        </p>
        <div className="d-flex-wrap">
          {props.editing !== 'so' &&
            itemIds.map((itemId, i) => {
              return (
                <button
                  type="button"
                  className={`d-flex-wrap-item ${
                    itemContent.id === itemId ? 'selected' : ''
                  }`}
                  key={i}
                  onClick={() => props.updateItem(props.editing, 'id', itemId)}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/img/simulator/category/${editingItemType}/${itemId}.png`}
                    alt={itemId}
                  />
                </button>
              );
            })}
        </div>
        { itemSetting && (
          <ColorSelector
          colors={itemSetting.colors.map(String)}
          item={props.item}
          editing={props.editing}
          attr={'color'}
          updateItem={props.updateItem}
         />
        )}
      </div>
      {itemSetting && itemSetting.numberTypeParams && (
        <div className="item-number-area mt20">
          <div className="fs14 white-border-b">
            <input
              type="checkbox"
              className="item-checkbox"
              id="checkIsNumber"
              checked={Boolean(itemContent.isNumber)}
              onChange={(e) =>
                props.updateItem(props.editing, 'isNumber', e.target.checked)
              }
            />
            {`Number - Type (全${itemSetting.numberTypeParams.fonts.length}型) / `}
            <span className="yellow-color">{itemContent.numberId}</span>
          </div>
          {itemContent.isNumber && (
            <>
              <div className="d-flex-wrap">
                {itemSetting.numberTypeParams.fonts.map((numberId, i) => {
                  return (
                    <button
                      type="button"
                      className={`d-flex-wrap-item ${
                        itemContent.numberId === numberId ? 'selected' : ''
                      }`}
                      key={i}
                      onClick={() =>
                        props.updateItem(props.editing, 'numberId', numberId)
                      }
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/img/simulator/number/${numberId}.png`}
                        alt={numberId}
                      />
                    </button>
                  );
                })}
              </div>
              <div className="category-item-number-positions mt10 fs12">
                ナンバー表示位置：
                {itemSetting.numberTypeParams.position.map((numberPosition, i) => {
                  const attr = camelcase(`isNumber ${numberPosition}`);
                  return (
                    <div className="category-item-number-position mr10" key={i}>
                      <input
                        type="checkbox"
                        className="item-checkbox"
                        id={`check${attr}`}
                        checked={Boolean(get(itemContent, attr))}
                        onChange={(e) => updateNumberPosition(attr, e.target.checked)}
                      />
                      <label
                        className="item-number-position-label fs12"
                        htmlFor={`check${attr}`}
                      >
                        {localized(numberPosition)}
                      </label>
                    </div>
                  );
                })}
              </div>
              <ColorSelector
                colors={itemSetting.numberTypeParams.colors.map(String)}
                item={props.item}
                editing={props.editing}
                attr="numberColor"
                updateItem={props.updateItem}
              />
            </>
          )}
        </div>
      )}
      {itemSetting && itemSetting.namePositionParams && (
        <>
          <div className="mt20">
            <ItemNamePosition
              item={props.item}
              editing={props.editing}
              itemSetting={itemSetting}
              updateItem={props.updateItem}
              updateDirection={props.updateDirection}
            />
          </div>
          <div className="item-sponsor-logo-option mt20">
            <input
              type="checkbox"
              className="item-checkbox"
              id="checkIsSponsorLogoOption"
              checked={Boolean(itemContent.isSponsorLogoOption)}
              onChange={(e) =>
                props.updateItem(
                  props.editing,
                  'isSponsorLogoOption',
                  e.target.checked
                )
              }
            />
            <label
              className="item-sponsor-logo-option-label"
              htmlFor="checkIsSponsorLogoOption"
            >
              【オプション】オリジナルのスポンサーロゴを入れる
              <p className="yellow-color fs10 mt5 item-sponsor-logo-option-desc line-height-1-5">
                ※決済後にデータ入稿をお願いします。
                <br className="only-sp" />
                <span className="zenkaku-space">&emsp;</span>
                表示位置など決済後にオペレーターと相談ください。
              </p>
            </label>
          </div>
        </>
      )}
    </>
  );
}

export default CategoryContent
