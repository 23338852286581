import './MaterialModal.scss';

const MaterialModal = (props) => {

  return (
    <div className="materials">
      <div className="material">
        <div className="material-img">
          <img src={`${process.env.PUBLIC_URL}/img/modal/material_img.png`} alt="materialImg" />
        </div>
        <div className="material-content">
          <div className="material-title">ALL アイテム</div>
          <div className="material-text">
            ポリエステル100%
            <br />
            ストレッチ性と吸汗速乾性に優れ、動きやすい素材です
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaterialModal
