import './OrderForm.scss';
import { useState, useEffect } from 'react';
import {categoryToItemType, price, checkJapaneseText} from '../utils'
import {findSku} from "../orderFormUtils";
import OrderFormRow from './orderForm/OrderFormRow';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import compact from 'lodash/compact';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';
import sumBy from 'lodash/sumBy';
import get from 'lodash/get';
import {
  DEFAULT_SIZE_BY_CATEGORY,
  LOCAL_STORAGE_ENCODED_SAVED_ITEM_KEY,
  LOCAL_STORAGE_ORDER_FORM_KEY
} from "../constants";
import ConfirmationModal from "./modals/ConfirmationModal";
import useModal from "../hooks/useModal";

const initOrderForm = () => {
  const url = new URL(window.location.href);
  const params = url.searchParams;
  let orderForms = [];
  if (params.has('data')) {
    orderForms = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ORDER_FORM_KEY)) || [];
    if (params.get('data') !== localStorage.getItem(LOCAL_STORAGE_ENCODED_SAVED_ITEM_KEY)) {
      orderForms = [];
    }
  }
  localStorage.removeItem(LOCAL_STORAGE_ORDER_FORM_KEY);
  localStorage.removeItem(LOCAL_STORAGE_ENCODED_SAVED_ITEM_KEY);
  return orderForms;
}

const OrderForm = (props) => {
  const [orderForms, setOrderForms] = useState(initOrderForm);

  const orderItems = () => {
    return pickBy(props.item, (item, _category) => {
      return item.order;
    });
  }

  const totalPrice = () => {
    return price(sumBy(orderForms, (orderForm) => orderForm.sku.price * orderForm.quantity));
  }

  const confirmationModalHooks = useModal();
  useEffect(() => {
    const updateOrderItems = (items) => {
      if (orderForms.length === 0) {
        return compact(
          map(items, (item, category) => {
            const size = DEFAULT_SIZE_BY_CATEGORY[categoryToItemType(category)];
            return {
              id: item.id,
              item: item,
              category: category,
              size: size,
              quantity: 1,
              sku: findSku(category, item.id, size, item.color),
              numbers: [""],
              personalNames: {backTop: [""], backBottom: [""]}
            };
          })
        );
      } else {
        for (const category in items) {
          const targetItems = filter(orderForms, (orderForm) => {
            return orderForm.category === category;
          })
          if (targetItems.length > 0) {
            for (const targetItem of targetItems) {
              targetItem.id = items[category].id;
              targetItem.item = items[category];
              targetItem.sku = findSku(category, targetItem.id, targetItem.size, targetItem.item.color);
              for (const position of ["backTop", "backBottom"]) {
                if (get(targetItem.item.namePosition, `${position}.kind`) === "personalName") {
                  targetItem.personalNames[position] = get(targetItem.personalNames, position).map((text, _i) => {
                    return checkJapaneseText(get(targetItem.item.namePosition, `${position}.fontId`), text);
                  });
                }
              }
            }
          } else {
            const size = DEFAULT_SIZE_BY_CATEGORY[categoryToItemType(category)];
            orderForms.push({
              id: items[category].id,
              item: items[category],
              category: category,
              size: size,
              quantity: 1,
              sku: findSku(category, items[category].id, size, items[category].color),
              numbers: [""],
              personalNames: {backTop: [""], backBottom: [""]}
            })
          }
        }
        return filter(orderForms, (orderForm) => {
          return Object.keys(items).includes(orderForm.category);
        });
      }
    };

    setOrderForms(cloneDeep(updateOrderItems(orderItems())));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item]);

  return (
    <>
      <div id="orderFormInput" className="order-form-input">
        <h2 className="order-form-input-head">
          注文フォーム
          <span className="only-pc order-form-input-head-desc fs14">
            （シミュレーターで作成したデザインのセット内容が反映されています）
          </span>
        </h2>
        <span className="only-sp fs10 mt10">
          ※シミュレーターで作成したデザインのセット内容が反映されています
        </span>
        {orderForms.length > 0 && (
          <>
            <div className="order-form-table-wrapper">
              <table id="orderFormTable" className="order-form-table">
                <thead className="order-form-table-header">
                  <tr className="order-form-table-header-row">
                    <th className="product-name">商品名</th>
                    <th className="product-size">サイズ</th>
                    <th className="product-quantity">数量</th>
                    <th className="product-detail">詳細内容</th>
                    <th className="product-price">費用（税込）</th>
                    <th className="action">追加/削除</th>
                  </tr>
                </thead>
                <tbody className="order-form-table-body">
                  {orderForms.map((orderForm, i) => {
                    return (
                      <OrderFormRow
                        key={i}
                        index={i}
                        orderForm={orderForm}
                        orderForms={orderForms}
                        setOrderForms={setOrderForms}
                        item={props.item}
                        itemDispatch={props.itemDispatch}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="order-form-input-anotations">
              <p className="fs12 line-height-1-5">
                ※チーム名の表示位置を指定したい方は購入画面で決済完了後にオペレーターとお繋ぎしますので、その際にご希望箇所をお伝えください。
                <br />
                ※オリジナルのスポンサーロゴ反映をオプション選択された方は、購入画面で決済完了後にオペレーターとお繋ぎしますので、別途データのご入稿をお願いします。
                <br />
                ※ナンバーについては10番（2桁）で計算しています。番号を選択していない場合は金額に含まれていません。
              </p>
            </div>
          </>
        )}
      </div>
      {orderForms.length > 0 && (
        <div id="summary" className="summary">
          <div className="summary-inner">
            <div className="summary-price-wrapper">
              <div className="summary-price">
                <div className="summary-price-label">TOTAL</div>
                <div className="summary-price-content">
                  ￥{totalPrice()}（税込）
                </div>
              </div>
              <p className="red-color mt20 line-height-1-5 summary-annotation">
                決済画面で代引きが表示されますが、代理店様以外はクレジット決済のみ受け付けております。ご注意ください。
              </p>
            </div>
            <div className="summary-btns">
              <a className="summary-btn reedit-design-btn" href="#app">
                デザインを再編集する
              </a>
              <button
                className="summary-btn payment-btn"
                onClick={() => confirmationModalHooks.openModal()}
              >
                上記内容で決済画面へ
              </button>
            </div>
          </div>
        </div>
      )}
      {orderForms.length === 0 && (
        <p className="no-order">まだどのアイテムも注文対象になっていません。</p>
      )}
      <ConfirmationModal
        modalStatus={confirmationModalHooks.modalStatus}
        closeModal={confirmationModalHooks.closeModal}
        orderForms={orderForms}
        item={props.item}
      />
    </>
  );
};

export default OrderForm
