import './Mannequin.scss'
import {
  MANNEQUIN_SETTINGS_BY_CATEGORY,
  FONT_FAMILY_BY_FONT_ID,
  COLOR_CODE_BY_ID,
  NAME_DEFAULT_SCALE_BY_POSITION,
  MANNEQUIN_DEFAULT_SETTINGS,
  MANNEQUIN_UPPER_ITEM_BY_CATEGORY_TYPES,
  MANNEQUIN_LOWER_ITEM_BY_CATEGORY_TYPES,
  CATEGORY_TYPE_BIBS
} from '../../constants';
import { categoryToItemType, findItemSetting } from '../../utils';
import AthletaLogoSvg from '../AthletaLogoSvg';
import styled from 'styled-components';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase'

const MannequinPartImg = (props) => {
  return <img src={`${process.env.PUBLIC_URL}/img/mannequin/${props.direction}/${props.part}.png`} alt={`mannequin${props.direction}${props.part}`} />;
}

const ItemSvg = (props) => {
  return (
    <img
      src={`${process.env.PUBLIC_URL}/img/item/${props.itemType}/${props.itemContent.id}_${COLOR_CODE_BY_ID[props.itemContent.color]}_${props.direction}.svg`}
      alt={`item${props.itemType}${props.itemContent.id}`}
    />
  );
}

const NumberSvg = (props) => {
  return (
    <img
      src={`${process.env.PUBLIC_URL}/img/number/${props.itemContent.numberId}_${COLOR_CODE_BY_ID[props.itemContent.numberColor]}_${props.direction}.svg`}
      alt={`number${props.itemContent.numberId}`}
    />
  )
}

const Number = (props) => {
  return (
    <>
      { props.direction === "F" && props.itemContent.isNumberSideChest && (
        <div className="number-side-chest">
          <NumberSvg {...props} />
        </div>
      )}
      { props.direction === "F" && props.itemContent.isNumberCenterChest && (
        <div className="number-center-chest">
          <NumberSvg {...props} />
        </div>
      )}
      { props.direction === "F" && props.itemContent.isNumberRightThigh && (
        <div className="number-right-thigh">
          <NumberSvg {...props} />
        </div>
      )}
      { props.direction === "F" && props.itemContent.isNumberFrontLeftHem && (
        <div className="number-front-left-hem">
          <NumberSvg {...props} />
        </div>
      )}
      { props.direction === "B" && props.itemContent.isNumberBack && (
        <div className="number-back">
          <NumberSvg {...props} />
        </div>
      )}
    </>
  );
}

const NameText = styled.div`
  color: ${({ $color }) => `#${COLOR_CODE_BY_ID[$color]}`};
  font-family: ${({ $fontFamily }) => `${FONT_FAMILY_BY_FONT_ID[$fontFamily]}`};
  transform: ${({ $transform }) => `${$transform}`};
`;

const Name = (props) => {
  const itemSetting = findItemSetting(categoryToItemType(props.category), props.itemContent.id);
  const namePositionContent = get(props.itemContent, "namePosition");
  const nameTransform = (position, text, size) => {
    let scale = get(NAME_DEFAULT_SCALE_BY_POSITION, `${position}.${text}`);

    if (size == null) {
      return scale;
    }

    let transform = position === "frontSide" ? "" : "translateX(-50%) ";
    scale = scale * size / 100;
    transform += ` scale(${scale}, 0.9)`
    return transform;
  };

  const className = (position) => {
    let klass = kebabCase(position);
    if (!itemSetting.numberTypeParams || ["backTop","backBottom"].includes(position)) {
      return klass;
    }

    if (itemSetting.numberTypeParams.position.includes("centerChest")) {
      klass += " is-number-center-chest"
    } else if (itemSetting.numberTypeParams.position.includes("sideChest")) {
      klass += " is-number-side-chest"
    } else if (itemSetting.numberTypeParams.position.includes("frontLeftHem")) {
      klass += " is-number-front-left-hem"
    }
    return klass;
  }

  return (
    <>
      { props.direction === "F" && (
        <>
          {["frontCenter", "frontSide"].map((position, i) => {
            const kind = get(namePositionContent, `${position}.kind`);
            if (kind === "teamName") {
              return (
                <div className={className(position)} key={i}>
                  {["upperText", "lowerText"].map((text, i) => {
                    return (
                      <NameText
                        key={i}
                        className={kebabCase(text)}
                        $color={get(namePositionContent, `${position}.color`)}
                        $fontFamily={get(namePositionContent, `${position}.fontId`)}
                        $transform={nameTransform(position, text, get(namePositionContent, `${position}.${text}Size`))}
                      >
                        {get(namePositionContent, `${position}.${text}`)}
                      </NameText>
                    )
                  })}
                </div>
              )
            } else if (kind === "athletaLogo") {
              return (
                <div className={className(position)} key={i}>
                  <div className="athleta-logo">
                    <AthletaLogoSvg
                      color={get(namePositionContent, `${position}.color`)}
                    />
                  </div>
                </div>
              )
            } else if (kind === "emblem") {
              return (
                <div className={className(position)} key={i}>
                  <div className="emblem">
                    <img src={`${process.env.PUBLIC_URL}/img/logo/emblem.svg`} alt="emblem" />
                  </div>
                </div>
              )
            }
            return null;
          })}
        </>
      )}
      { props.direction === "B" && (
        <>
          {["backTop", "backBottom"].map((position, i) => {
            const kind = get(namePositionContent, `${position}.kind`);
            if (kind === "teamName") {
              return (
                <div className={className(position)} key={i}>
                  {["upperText", "lowerText"].map((text, i) => {
                    return (
                      <NameText
                        key={i}
                        className={kebabCase(text)}
                        $color={get(namePositionContent, `${position}.color`)}
                        $fontFamily={get(namePositionContent, `${position}.fontId`)}
                        $transform={nameTransform(position, text, get(namePositionContent, `${position}.${text}Size`))}
                      >
                        {get(namePositionContent, `${position}.${text}`)}
                      </NameText>
                    )
                  })}
                </div>
              )
            } else if (kind === "personalName")  {
              return (
                <div className={className(position)} key={i}>
                  <NameText
                    key={i}
                    className={"personal-name"}
                    $color={get(namePositionContent, `${position}.color`)}
                    $fontFamily={get(namePositionContent, `${position}.fontId`)}
                    $transform={nameTransform(position, "personalName", 100)}
                  >
                    NAME
                  </NameText>
                </div>
              )
            } else if (kind === "athletaLogo") {
              return (
                <div className={className(position)} key={i}>
                  <div className="athleta-logo">
                    <AthletaLogoSvg
                      color={get(namePositionContent, `${position}.color`)}
                    />
                  </div>
                </div>
              )
            }
            return null;
          })}
        </>
      )}
    </>
  )
}

const MannequinImg = (props) => {
  const mannequinProps = { direction: props.direction, itemContent: props.itemContent };
  const mannequinKlass = () => {
    let klass = `mannequin-${props.part}`;
    if (props.type === "name") {
      klass += " mannequin-name";
    } else if (props.type === "number") {
      klass += " mannequin-number";
    } else {
      klass += " mannequin-part";
    }
    return klass;
  }

  let show = false;
  if (props.type === "item") {
    mannequinProps.itemType = categoryToItemType(props.category);
    show = !!props.itemContent.id;
  } else if (props.type === "number") {
    show = !!props.itemContent.isNumber;
  } else if (props.type === "name") {
    mannequinProps.category = categoryToItemType(props.category);
    show = !!props.itemContent.isName;
  } else if (props.type === "mannequin") {
    mannequinProps.part = "mannequin_" + categoryToItemType(props.category);
    show = props.withBody;
  }
  if (!show) return null;

  return (
    <div className={mannequinKlass()}>
      { props.type === "item" && (
        <ItemSvg {...mannequinProps} />
      )}
      { props.type === "name" && (
        <Name {...mannequinProps} />
      )}
      { props.type === "number" && (
        <Number {...mannequinProps} />
      )}
      { props.type === "mannequin" && (
        <MannequinPartImg {...mannequinProps} />
      )}
    </div>
  )
}

const Mannequin = (props) => {
  let upperCategory = props.prevEditingUpper;
  let lowerCategory = props.prevEditingLower;
  const itemContent = props.item[props.editing]
  if (props.editing && itemContent.id) {
    const itemType = categoryToItemType(props.editing)
    if (MANNEQUIN_UPPER_ITEM_BY_CATEGORY_TYPES.includes(itemType)) {
      upperCategory = props.editing;
    } else if (MANNEQUIN_LOWER_ITEM_BY_CATEGORY_TYPES.includes(itemType)) {
      lowerCategory = props.editing;
    }
  }
  return (
    <div id="mannequin" className="mannequin">
      <div className="mannequin-part mannequin-default-p">
        <ItemSvg
          direction={props.direction}
          itemType="p"
          itemContent={get(MANNEQUIN_DEFAULT_SETTINGS, 'p.itemContent')}
        />
      </div>
      <div className="mannequin-part mannequin-default-arms">
        <MannequinPartImg
          direction={props.direction}
          part="mannequin_arms"
        />
      </div>
      <div className="mannequin-part mannequin-default-t">
        <ItemSvg
          direction={props.direction}
          itemType="t"
          itemContent={get(MANNEQUIN_DEFAULT_SETTINGS, 't.itemContent')}
        />
      </div>
      { CATEGORY_TYPE_BIBS.includes(props.editing) && MANNEQUIN_SETTINGS_BY_CATEGORY[props.editing].map((mannequinSetting, i) => {
        return <MannequinImg
          {...mannequinSetting}
          key={i}
          direction={props.direction}
          editing={props.editing}
          itemContent={props.item[mannequinSetting.category]}
          withBody={props.withBody}
        />;
      })}
      { upperCategory && MANNEQUIN_SETTINGS_BY_CATEGORY[upperCategory].map((mannequinSetting, i) => {
        return <MannequinImg
          {...mannequinSetting}
          key={i}
          direction={props.direction}
          editing={props.editing}
          itemContent={props.item[mannequinSetting.category]}
          withBody={props.withBody}
        />;
      })}
      { lowerCategory && MANNEQUIN_SETTINGS_BY_CATEGORY[lowerCategory].map((mannequinSetting, i) => {
        return <MannequinImg
          {...mannequinSetting}
          key={i}
          direction={props.direction}
          editing={props.editing}
          itemContent={props.item[mannequinSetting.category]}
          withBody={props.withBody}
        />;
      })}
      <div className="mannequin-part mannequin-head">
        <MannequinPartImg
          direction={props.direction}
          part="mannequin_head"
        />
      </div>
    </div>
  );
}

export default Mannequin
