import './Main.scss';
import Simulator from './Simulator';
import OrderForm from './OrderForm';
import { useReducer } from 'react';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import useModal from '../hooks/useModal';
import Modal from './Modal';
import MaterialModal from './modals/MaterialModal';
import SizeChartModal from './modals/SizeChartModal';
import GenerateUrlModal from './modals/GenerateUrlModal';
import DefaultItem from '../json/default.json';
import { LOCAL_STORAGE_ITEM_KEY } from '../constants';
import pako from 'pako';

const itemReducer = (itemState, action) => {
  let cloneItem;
  switch (action.type) {
    case 'update':
      cloneItem = cloneDeep(action.item);
      localStorage.setItem(LOCAL_STORAGE_ITEM_KEY, JSON.stringify(cloneItem));
      return cloneItem;
    case 'reset':
      cloneItem = cloneDeep(DefaultItem);
      localStorage.setItem(LOCAL_STORAGE_ITEM_KEY, JSON.stringify(cloneItem));
      return cloneItem;
    default:
      return itemState;
  }
};

const initItem = () => {
  const url = new URL(window.location.href);
  const params = url.searchParams;
  const strToBytes = (str) => {
    const length = str.length;
    const bytes = new Uint8Array(length);
    for(let i = 0; i < length; i++) {
      bytes[i] = str.charCodeAt(i);
    }
    return bytes;
  };

  if(params.has('data')) {
    const base64Decoded = atob(params.get('data'));
    const compressed = strToBytes(base64Decoded);
    const json = pako.ungzip(compressed, { to: 'string' });
    const obj = JSON.parse(json);
    for (const [key, value] of Object.entries(obj)) {
      params.set(key, value);
    }
  }

  if(params.size) {
    const fixedItem = cloneDeep(DefaultItem);
    for (const [key, value] of params) {
      const splited = key.split('_');
      const category = splited.shift();
      const attr = splited.join('.');
      set(fixedItem[category], attr, value);
    }
    if (isEmpty(pickBy(fixedItem, (value) => !isEmpty(value)))) {
      return DefaultItem;
    }
    return fixedItem;
  }

  let savedItem;
  try {
    savedItem = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEM_KEY)) || {};
  } catch (e) {
    savedItem = {};
  }

  if (Object.keys(savedItem).length > 0) {
    return savedItem;
  } else {
    return DefaultItem;
  }
};

const smoothScroll = (target) => {
  let top;
  if (target === "top") {
    top = 0;
  } else {
    const element = document.getElementById(target);
    top = element.getBoundingClientRect().top;
  }
  window.scroll({
    top: top,
    behavior: "smooth"
  })
}

const Main = (props) => {
  const [item, itemDispatch] = useReducer(itemReducer, initItem());
  const resetDesign = () => {
    itemDispatch({ type: "reset" });
  }
  const materialModalHooks = useModal();
  const sizeChartModalHooks = useModal();
  const generateUrlModalHooks = useModal();

  return (
    <>
      <main className="main">
        <Simulator
          item={item}
          itemDispatch={itemDispatch}
          openMaterialModal={materialModalHooks.openModal}
          openSizeChartModal={sizeChartModalHooks.openModal}
          openGenerateUrlModal={generateUrlModalHooks.openModal}
          resetDesign={resetDesign}
        />
        <div id="orderFormLinkArea" className="order-form-link-area">
          <a href="#orderFormInput" className="order-form-link" onClick={() => smoothScroll("orderFormInput")}>
            注文フォームの中身を見る
          </a>
        </div>
        <OrderForm
          item={item}
          itemDispatch={itemDispatch}
        />
        <button type="button" className="page-top-icon-link" onClick={() => smoothScroll("top")}>
          <img
            src="./img/page-top-icon.svg"
            className="page-top-icon"
            alt="pageTopIcon"
          />
        </button>
      </main>
      <Modal
        modalStatus={materialModalHooks.modalStatus}
        closeModal={materialModalHooks.closeModal}
        modalTitle="生地素材-MATERIAL-"
      >
        <MaterialModal />
      </Modal>
      <Modal
        modalStatus={sizeChartModalHooks.modalStatus}
        closeModal={sizeChartModalHooks.closeModal}
        modalTitle="サイズ-SIZE-"
      >
        <SizeChartModal />
      </Modal>
      <GenerateUrlModal
        modalStatus={generateUrlModalHooks.modalStatus}
        closeModal={generateUrlModalHooks.closeModal}
        item={item}
      />
    </>
  );
}

export default Main
