import pickBy from "lodash/pickBy";
import map from "lodash/map";
import find from 'lodash/find';
import has from 'lodash/has';
import { localized, findItemSetting, categoryToItemType } from "./utils";

const KIDS_SIZES = ["1921"]; //該当サイズはhandle３桁数値末尾にKをつける
const JUNIOR_SIZES = ["110", "120", "130", "140", "150", "160", "2224", "JF"]; //該当サイズはhandle３桁数値末尾にJをつける

export const numberPositions = (item) => {
  return pickBy(item, (value, key) => {
    return value && key.match(/^isNumber.+$/);
  });
}

export const displayNumberPosition = (item) => {
  const numbers = numberPositions(item);
  return map(Object.keys(numbers), (number) => localized(number)).join("/");
}

export const sortNamePositions = (namePositions) => {
  const kindOrder = ["teamName", "personalName", "athletaLogo", "emblem"];
  const namePositionOrders = [];

  for (const namePosition in namePositions) {
    namePositions[namePosition].position = namePosition
    namePositionOrders.push(namePositions[namePosition]);
  }

  return namePositionOrders.sort((x, y) => {
    return kindOrder.indexOf(x.kind) - kindOrder.indexOf(y.kind);
  });
}

const generateSkuKey = (itemId, size, color) => {
  let sku = `${itemId}-${color}-${size}`;
  if (KIDS_SIZES.includes(size)) {
    // Change SKU to ***-nnnK format
    sku = `${itemId.replace(/(\d+)([A-Z]{0,2})?(.*)$/, '$1K$2$3')}-${color}-${size}`;
  } else if (JUNIOR_SIZES.includes(size)) {
    // Change SKU to ***-nnnJ format
    sku = `${itemId.replace(/(\d+)([A-Z]{0,2})?(.*)$/, '$1J$2$3')}-${color}-${size}`;
  }
  return sku;
}

export const findSku = (category, itemId, size, color) => {
  const skuKey = generateSkuKey(itemId, size, color);
  const itemSetting = findItemSetting(categoryToItemType(category), itemId);
  const sku = find(itemSetting.skus, (sku) => has(sku, skuKey));
  return {
    skuKey: skuKey,
    variantId: sku[skuKey]?.variantId,
    price: sku[skuKey]?.price,
  }
}
