import { localized, checkJapaneseText } from '../../utils';
import { COLOR_NAME_BY_ID } from '../../constants';
import get from 'lodash/get';

const OrderFormName = (props) => {
  const updatePersonalName = (index, value) => {
    props.orderForm.personalNames[props.namePosition.position][index] = checkJapaneseText(props.namePosition.fontId, value);
    props.updateOrderForm("personalNames", props.orderForm.personalNames);
  }

  const nameSizeText = (attr) => {
    const size = get(props.namePosition, attr)
    if (size !== 100) { return null; }
    return "（お任せサイズ）"
  }

  if (props.namePosition.kind === "teamName") {
    return (
      <>
        <p className="mt5 line-height-1-5">チーム名表示位置：{localized(props.namePosition.position)}</p>
        <p className="mt5 line-height-1-5">チーム名上段テキスト：{props.namePosition.upperText}{nameSizeText("upperTextSize")}</p>
        <p className="mt5 line-height-1-5">チーム名下段テキスト：{props.namePosition.lowerText}{nameSizeText("lowerTextSize")}</p>
        <p className="mt5 line-height-1-5">チーム名フォント：{props.namePosition.fontId}</p>
        <p className="mt5 line-height-1-5">チーム名カラー：{COLOR_NAME_BY_ID[props.namePosition.color]}</p>
      </>
    )
  } else if (props.namePosition.kind === "personalName") {
    return (
      <>
        <p className="mt5 line-height-1-5">個人名表示位置：{localized(props.namePosition.position)}</p>
        { props.orderForm.personalNames[props.namePosition.position].map((name, i) => {
          return (
            <p className="mt5 line-height-1-5" key={i}>
              {`個人名${i+1}：`}
              <input
                type="text"
                className="order-form-personal-name-input"
                value={name}
                onChange={(e) => {if (e.target.value.length <= 15) {updatePersonalName(i, e.target.value)}}}
                maxLength={15}
              />
            </p>
          )
        })}
        <p className="mt5 line-height-1-5">個人名フォント：{props.namePosition.fontId}</p>
        <p className="mt5 line-height-1-5">個人名カラー：{COLOR_NAME_BY_ID[props.namePosition.color]}</p>
      </>
    )
  } else if (props.namePosition.kind === "athletaLogo") {
    return (
      <>
        <p className="mt5 line-height-1-5">アスレタロゴ表示位置：{localized(props.namePosition.position)}</p>
        <p className="mt5 line-height-1-5">アスレタロゴカラー：{COLOR_NAME_BY_ID[props.namePosition.color]}</p>
      </>
    )
  } else if (props.namePosition.kind === "emblem") {
    return (
      <p className="mt5 line-height-1-5">【オプション】エンブレム表示有無： 有</p>
    );
  }
  return null;
}

export default OrderFormName
